<template>
  <div>
    <b-tabs
      lazy
      vertical
      nav-class="nav-right"
      nav-wrapper-class="nav-vertical"
    >
      <b-tab
        active
        title="Main"
      >
        <DashboardMain />
      </b-tab>
      <b-tab
        v-if="BookingDashboard"
        title="Booking"
      >
        <DashboardBooking />
      </b-tab>
    </b-tabs>

  </div>
</template>

<script>
import DashboardMain from './DashboardMain.vue'
import DashboardBooking from './DashbordBooking.vue'

export default {
  components: {
    DashboardMain,
    DashboardBooking,
  },
  data() {
    return {
      Module: {},
      Role: '',
      BookingDashboard: false,
    }
  },
  beforeMount() {
    this.LoadAll()
  },
  mounted() {
    this.LoadingEnd()
  },
  methods: {
    LoadAll() {
      this.LoadKey()
      // Get user role
      this.Role = this.GetUserRole()
      // Get module
      this.Module = this.GetUserModule()
      this.Module.forEach(c => {
        switch (c.subject) {
          default:
            break
          case 'Booking':
            this.BookingDashboard = true
            break
        }
      })
    },
    LoadKey() {
      this.$http.defaults.headers.common = { 'api-key': this.$store.state.user.AppActiveUser.Id, UserId: this.$store.state.user.AppActiveUser.Id }
    },
  },
}
</script>

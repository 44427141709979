<template>
  <div>
    <b-card>
      <b-button-toolbar justify>
        <div style="width:75%">
          <b-row>
            <b-col md="6">

              <b-form-group>
                Date range:
                <flat-pickr
                  v-model="rangeDate"
                  class="form-control"
                  :config="{ mode: 'range'}"
                />
              </b-form-group>
            </b-col>

          </b-row>

        </div>
        <div>
          <p>&nbsp;</p>
          <b-button
            v-b-tooltip.hover="'Reset'"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="warning"
            class="btn-icon mb-1"
            @click="LoadAll()"
          >
            <font-awesome-icon
              :icon="['fas', 'undo']"
              size="lg"
            />
          </b-button>&nbsp;
          <b-button
            v-b-tooltip.hover="'View'"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="btn-icon mb-1"
            @click="UpdateRangeData()"
          >
            <font-awesome-icon
              :icon="['fas', 'eye']"
              size="lg"
            />
          </b-button>
        </div>
      </b-button-toolbar>
    </b-card>

    <b-row>
      <b-col md="4">
        <b-card title="Booking Status">
          <apexchart
            id="BookingStatusChart"
            type="donut"
            height="350"
            :options="BK.BkStatus.chartOptions"
            :series="BK.BkStatus.series"
          />
        </b-card>
      </b-col>
      <b-col md="8">
        <b-card title="Shipment Status">
          <apexchart
            type="bar"
            height="350"
            :options="BK.ShipmentStatus.chartOptions"
            :series="BK.ShipmentStatus.series"
          />
        </b-card>
      </b-col>
    </b-row>

  </div>
</template>

<script>
import axios from '@axios'
import moment from 'moment'
import flatPickr from 'vue-flatpickr-component'
import BK from './DashboardBooking'

export default {
  components: {
    flatPickr,
  },
  data() {
    return {
      BK,
      rangeDate: null,
      StartDate: '',
      EndDate: '',
    }
  },
  beforeMount() {
    axios.defaults.headers.common.DBContext = this.$store.state.user.AppActiveUser.BookingDbContext
    this.LoadAll()
    this.SetThisWeek()
  },
  mounted() {
    this.LoadingEnd()
  },
  methods: {
    SetThisWeek() {
      const startOfWeek = moment().startOf('week').format('YYYY-MM-DD')
      const endOfWeek = moment().endOf('week').format('YYYY-MM-DD')
      this.rangeDate = `${startOfWeek} to ${endOfWeek}`
      this.UpdateRangeData()
      return true
    },
    UpdateRangeData() {
      const SplitDate = this.rangeDate.split('to')
      this.StartDate = new Date(SplitDate[0])
      this.EndDate = new Date(SplitDate[1])
      this.LoadChartsData()
    },
    LoadAll() {
      Promise.all([this.LoadingStart(), this.SetThisWeek()]).then(() => {
        this.LoadChartsData()
        this.LoadingEnd()
      })
    },
    LoadChartsData() {
      this.LoadBookingStatus()
      this.LoadShipmentStatus()
    },
    LoadBookingStatus() {
      return axios.get('/booking/reports/bookings/status', {
        params: {
          StartDate: new Date(this.StartDate).toJSON().split('T')[0],
          EndDate: new Date(this.EndDate).toJSON().split('T')[0],
        },
      })
        .then(rowData => {
          BK.BkStatus.series = rowData.data
        })
    },
    LoadShipmentStatus() {
      return axios.get('/booking/reports/shipments/status', {
        params: {
          StartDate: new Date(this.StartDate).toJSON().split('T')[0],
          EndDate: new Date(this.EndDate).toJSON().split('T')[0],
        },
      })
        .then(rowData => {
          BK.ShipmentStatus.series = [{ name: 'HBL', data: rowData.data }]
        })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
